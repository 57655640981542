export enum RoutesMap {
    HISTORY = "/",
    READING_PAGE = "/stories/:storyId",
    CREATE_STORY = "/create",
    VOCABS = "/vocabs",
}

export const difficultyLevels = [
    "A1",
    "A2",
    "B1",
    "B2",
    "C1",
    "C2",
]

export const caseTypesArray = [
    "Nominativ",
    "Akkusativ",
    "Dativ",
    "Genitiv",
]

export const tenseTypesArray = [
    "Präsens",
    "Präteritum",
    "Perfekt",
    "Plusquamperfekt",
    "Futur I",
    "Futur II",
]
