import React, { useState, useEffect } from 'react';
import { ChakraProvider, Text, Box, Button, FormControl, Select, Input, Slider, Heading, theme, Card, CardBody, IconButton } from '@chakra-ui/react';
import { HistoryItem } from '../types/HistoryItem';
import { useNavigate, Link } from 'react-router-dom';
import { StoryTags } from '../components/StoryTags';
import { BiTrash } from 'react-icons/bi';


export const HistoryPage = () => {
  const [history, setHistory] = useState<HistoryItem[]>([]);
  const [difficultyLevel, setDifficultyLevel] = useState('A1');
  const [caseType, setCaseType] = useState('dative');
  const [timeType, setTimeType] = useState('present');
  const [generatedText, setGeneratedText] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const localStorageHistory = window.localStorage.getItem('stories');
    setHistory(localStorageHistory ? JSON.parse(localStorageHistory) : []);
  }, []);

  const deleteHistoryItem = (id: string) => {
    const newHistory = history.filter(item => item.id !== id);
    setHistory(newHistory);
    window.localStorage.setItem('stories', JSON.stringify(newHistory));
  }

  return (
    <div className="flex flex-col w-full h-screen">
        <Box bg="red.300" p={4}>
        <Heading size="lg">Learn German By Story</Heading>
        </Box>

        <Box bg="white" p={4}>
        
        <Button mr={4} onClick={() => navigate('/create')}>Generate Story</Button>
        <Button onClick={() => navigate('/vocabs')}>Vocabs History</Button>

        {history.slice(0).reverse().map(item => (
          <Link to={`/stories/${item.id}`} key={item.id}>
            <Card mt={4} key={item.id}>
              <CardBody>
                <Text fontSize='xl'>
                  <IconButton aria-label="delete"
                          float={'right'}
                          onClick={(e) => {deleteHistoryItem(item.id); e.preventDefault();}}
                          icon={<BiTrash />}
                          ml={2} />
                  
                  {item.title}
                  <StoryTags story={item} />
                </Text>
              </CardBody>
            </Card>
          </Link>
        ))}
        
        </Box>
    </div>
  );
};
