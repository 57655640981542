import { ChakraProvider, theme } from '@chakra-ui/react';
import React from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { HistoryPage } from './pages/HistoryPage';
import { CreateStoryPage } from './pages/CreateStoryPage';
import { ReadingPage } from './pages/ReadingPage';
import { RoutesMap } from './types/constants';
import { VocabsPage } from './pages/VocabsPage';

export const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path={RoutesMap.HISTORY} element={<HistoryPage />} />
          <Route path={RoutesMap.READING_PAGE} element={<ReadingPage />} />
          <Route path={RoutesMap.CREATE_STORY} element={<CreateStoryPage />} />
          <Route path={RoutesMap.VOCABS} element={<VocabsPage />} />
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
};

