import React, { useState, useEffect } from 'react';
import { ChakraProvider, Text, Box, Button, FormControl, Select, Input, Slider, Heading, theme, Table, Thead, Tr, Th, Tbody, Td } from '@chakra-ui/react';
import { HistoryItem } from '../types/HistoryItem';
import { useNavigate, Link } from 'react-router-dom';


export const VocabsPage = () => {
  const [history, setHistory] = useState<HistoryItem[]>([]);
  const [searchInput, setSearchInput] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const localStorageHistory = window.localStorage.getItem('stories');
    setHistory(localStorageHistory ? JSON.parse(localStorageHistory) : []);
  }, []);

  return (
    <div className="flex flex-col w-full h-screen">
        <Box bg="red.300" p={4}>
        <Heading size="lg">Vocabulary History</Heading>
        </Box>

        <Box bg="white" p={4}>
        
          <Input placeholder="Search" onChange={(event) => setSearchInput(event.target.value)} />

          <Table>
            <Thead>
              <Tr>
                <Th>German</Th>
                <Th>English</Th>
              </Tr>
            </Thead>
            <Tbody>
              {history.map(item => (
                item.vocabs
                .filter(vocab => searchInput ? (vocab.key.toLowerCase().includes(searchInput.toLowerCase()) || vocab.value.toLowerCase().includes(searchInput.toLocaleLowerCase())) : true)
                .map((vocab, index) => (
                  <Tr key={index}>
                    <Td>{vocab.key}</Td>
                    <Td>{vocab.value}</Td>
                  </Tr>
                ))
              ))}
            </Tbody>
          </Table>
        </Box>
    </div>
  );
};
