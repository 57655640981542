import React, { useState, useEffect } from 'react';
import {
  ChakraProvider,
  Text,
  Box,
  Button,
  FormControl,
  Select,
  Input,
  Slider,
  Heading,
  theme,
  Tag,
  TagLabel,
  TagRightIcon,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  UnorderedList,
  ListItem,
  Divider,
  IconButton, Tooltip, Image
} from '@chakra-ui/react';
import { HistoryItem } from '../types/HistoryItem';
import { useParams } from 'react-router-dom';
import ImmersiveReader from '../ImmersiveReader';
import { StoryTags } from '../components/StoryTags';
import { InfoIcon } from '@chakra-ui/icons';
import {BiArrowBack, BiArrowToLeft, BiCog, BiHome, BiLink, BiRedo, BiReply, BiUndo} from "react-icons/all";
import {RoutesMap} from "../types/constants";


export const ReadingPage = () => {
  const [history, setHistory] = useState<HistoryItem[]>([]);
  const [difficultyLevel, setDifficultyLevel] = useState('A1');
  const [caseType, setCaseType] = useState('dative');
  const [timeType, setTimeType] = useState('present');
  const { storyId } = useParams();
  const backendRootUrl = 'https://backend-worker.pezhman32.workers.dev'; //window.location.protocol + '//' + window.location.hostname + ':3001'; //TODO fix this

  // find story from history with id found in the url
  const story = history.find(item => item.id === storyId);

  useEffect(() => {
    const localStorageHistory = window.localStorage.getItem('stories');
    setHistory(localStorageHistory ? JSON.parse(localStorageHistory) : []);
  }, []);

  console.log('>>> History', history);
  console.log('>>> Story', story);

  const handleReadStory = (id: number) => {
    alert('Read story with id: ' + id);
  };
  
  const exitCallback = () => {
    console.log("This is the callback function. It is executed when the Immersive Reader closes.");
  }

  if (!story) {
    return (
      <div className="flex flex-col w-full h-screen">
        <Box bg="red.300" p={4}>
          <Heading size="lg">Story not found</Heading>
        </Box>
      </div>
    );
  }

  const increaseReadingCount = () => {
    story.readsCount = (story.readsCount ? story.readsCount : 0) + 1;
    
    window.localStorage.setItem('stories', JSON.stringify(history));
    setHistory(history);
  }

  const immersiveReader = (buttonText: string) => {
    return (<ImmersiveReader
      buttonText={buttonText}
      onClick={increaseReadingCount}
      title={story.title}
      text={() => document.getElementById('story-de-text')!.innerHTML}
      tokenURL={backendRootUrl+'/api/immersive-reader/GetTokenAndSubdomain'}
      options={
          {
              'onExit': exitCallback,
              'uiZIndex': 2000,
              'translationOptions': {
                'language': 'en-us',
                'autoEnableWordTranslation': true,
              },
              'readAloudOptions': {
                'speed': 0.75,
                'autoPlay': true,
              },
              'displayOptions': {
                  'textSize': 14,
              }
          }
      }
      />)
  };

  return (
    <div className="flex flex-col w-full h-screen">
        <Box bg="red.300" p={4}>
          <Heading size="lg">
            {story.title.substring(0, 17)}...
            <div style={{'float': 'right'}}>
              {immersiveReader('')}
            </div>
          </Heading>
        </Box>

        <Box bg="white" p={4}>
          <StoryTags story={story} />

          <div id="story-content">
            <div id="story-de-text">
              <Text fontSize='3xl' lang='de'>
                {story?.title}
              </Text>
              
              {story?.text.split('\n').map((item, index) => (
                <Text fontSize='xl' lang='de' key={`story-line-${index}`}>
                  {story.type == 'news' && index == 0 && story.newsContext?.urlToImage && 
                    <Image
                    boxSize='50%'
                    objectFit='cover'
                    float='right'
                    ml={3}
                    mb={3}
                    src={story.newsContext!.urlToImage}
                  />}
                  {item}
                </Text>
              ))}
            </div>
            <br />
            
            {immersiveReader('Read & translate')}

            <Tooltip label="Home">
              <IconButton aria-label="Home"
                  onClick={() => window.location.href = RoutesMap.HISTORY}
                  icon={<BiHome />}
                  ml={2}
              />
            </Tooltip>

            <Tooltip label="Create new story">
              <IconButton aria-label="Home"
                          onClick={() => window.location.href = RoutesMap.CREATE_STORY}
                          icon={<BiCog />}
                          ml={2}
              />
            </Tooltip>

            {story?.type == 'news' &&
            <Tooltip label="Link to news">
              <IconButton aria-label="Link to news"
                          onClick={() => window.open(story.newsContext!.url, '_blank')}
                          icon={<BiLink />}
                          ml={2}
              />
            </Tooltip>
            }

            <Divider mt={5} mb={5} />

            <Text fontSize='3xl'>Vocabulary</Text>
            <TableContainer>
              <Table size='sm'>
                <Thead>
                  <Tr>
                    <Th>German</Th>
                    <Th>English</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {story?.vocabs.map((item, index) => (
                    <Tr key={index}>
                      <Td lang='de'>{item.key}</Td>
                      <Td lang='en'>{item.value}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>

            {/* <ul>
              {story?.vocabs.map((item, index) => (
                <li>
                  <div lang='de'>{item.key}</div>: <div lang='en'>{item.value}</div>
                </li>
              ))}
            </ul> */}
            
            <Text mt={5} fontSize='3xl'>Grammar</Text>
            {story?.grammar.map((item, index) => (
              <Text fontSize='l' mt={2}>
                <InfoIcon /> <b>{item.key}</b>: {item.value}
              </Text>
            ))}
          </div>

        </Box>
    </div>
  );
};
