import { Tag } from "@chakra-ui/react";
import { HistoryItem } from "../types/HistoryItem";

export const StoryTags = ({story}: {story: HistoryItem}) => {
    console.log('>>> StoryTags', story);
    return (
        <div className="story-tags">
          {/* <Tag colorScheme="blue" size="md" mr={2}>{story.type}</Tag> */}
          <Tag colorScheme="blue" size="md" mt={2} mr={2}>Level: {story.difficultyLevel}</Tag>
          {story.caseType && <Tag colorScheme="blue" size="md" mt={2} mr={2}>{story.caseType}</Tag>}
          {story.tense && <Tag colorScheme="blue" size="md" mt={2} mr={2}>{story.tense}</Tag>}
          <Tag colorScheme="blue" size="md" mt={2} mr={2}>
            {story.text?.split(' ').length} words
            {/* <TagRightIcon as={MdLuText} /> */}
          </Tag>
          {story.readsCount && <Tag colorScheme="orange" size="md" mt={2} mr={2}>{story.readsCount} reads</Tag>}
        </div>
    );
};