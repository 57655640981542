import * as React from 'react'
import { renderButtons, launchAsync }  from  "@microsoft/immersive-reader-sdk";
import { Button, Icon } from '@chakra-ui/react';
import { MdVolumeUp } from 'react-icons/md';

export interface Props {
  title: string;
  buttonText: string;
  onClick?: () => void;
  text: () => string;
  tokenURL: string;
  locale?: string;
  options: any;
}

export class ImmersiveReader extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount () {
    renderButtons();
}

getTokenandLaunch() {
  if (this.props.onClick) {
    this.props.onClick();
  }

  fetch(this.props.tokenURL, { /*credentials: `include`,*/ cache: "no-store" })
  .then(response => response.json())
  .then (body => {
        console.log('>>> content', this.props.text());
        const data = {
            title: this.props.title,
            chunks: [{
                content: this.props.text(),
                //lang:"en-us",
                mimeType: "text/html"
                //mimeType: "text/plain"
            }]
        };
        console.log("Launching Immersive Reader", data);
        launchAsync(body.token, body.subdomain, data, this.props.options)
           .catch(function (error:any) {
                  console.log("Error in launching the Immersive Reader.");
                  console.log(error);
              }); 
            })
    .catch((error) => {
  console.error('Error:', error);
});
}
  
render() {
  return (
    <Button
      colorScheme='teal'
      onClick={() => this.getTokenandLaunch()} 
      variant='solid'>
      {this.props.buttonText}
      <Icon as={MdVolumeUp} ml={this.props.buttonText ? 2 : 0} />
    </Button>
    // <button 
    //       className="immersive-reader-button" 
    //       onClick={()=>this.getTokenandLaunch()} 
    //       data-button-style="iconAndText" 
    //       data-locale={this.props.locale} />
   );}
}

export default ImmersiveReader;
